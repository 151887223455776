@import './utils/normalize.scss';

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
}

$size-m: 1rem;
$size-l: 1.5rem;
$size-xl: 2rem;

$brand-01: #0f62fe;
$brand-02: #4589ff;
$ui-01: #161616;
$text-01: #161616;
$text-02: #ffffff;

$transition-time: 250ms;

body {
  font-family: system-ui, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell,
    'Helvetica Neue', sans-serif;
  font-weight: 400;
  padding: $size-m 5%;
  margin: 0 auto;
  max-width: 1000px;
  color: $ui-01;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    padding: $size-xl $size-m;
    align-items: flex-start;

    p {
      line-height: 1.5;
    }
  }
}

.link {
  transition: all $transition-time;
  font-weight: 700;
  color: $text-01;
  background-position: -100%;
  background-image: linear-gradient(
    to left,
    transparent,
    transparent 50%,
    $brand-02 50%,
    $brand-02
  );
  background-position: 100% 0;
  background-size: 200% 100%;
  background-repeat: no-repeat;

  &:visited {
    color: $text-01;
  }

  &:hover {
    background-position: 0 0;
  }
}

.button {
  background-color: $brand-01;
  border: 0;
  padding: 16px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: $text-02;
  transition: all $transition-time;

  svg {
    margin-left: 32px;
    width: $size-m;
    height: $size-m;
    vertical-align: middle;
    transition: all $transition-time;
    fill: $text-02;
  }

  &:hover {
    cursor: pointer;
    background-color: #0353e9;
  }
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 600px) {
    height: 100%;
  }
}

.title {
  .name {
    padding-left: 16px;
    display: inline-block;
    font-weight: 700;
    font-size: 4rem;
    margin-bottom: $size-xl;
  }

  .bio {
    padding-left: 16px;
    font-size: 1.25rem;
  }

  .location {
    padding-left: 16px;
    font-style: italic;
    margin: $size-m 0 $size-xl;
  }

  .button {
    position: relative;

    &:focus {
      outline: 2px solid $text-02;
      outline-offset: -4px;
    }
  }

  .tooltip {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: all $transition-time;
    position: absolute;
    background-color: $text-01;
    color: $text-02;
    padding: 16px;
    height: 40px;
    font-size: 14px;
    width: 100%;
    transform: translateX(100%);
    visibility: visible;
    opacity: 1;

    span {
      white-space: nowrap;
    }

    &.hidden {
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
    }
  }
}

footer {
  @media (max-width: 600px) {
    margin-top: auto;
  }
}

.social {
  display: flex;
  margin-top: $size-xl;
  padding: 0 0 0 16px;
  position: relative;

  li {
    list-style: none;
    display: flex;
    align-items: center;
    margin-right: $size-m;
    transition: all $transition-time;
    padding: 0.25rem;
    border-radius: 100%;

    a {
      height: $size-l;
      width: $size-l;
      display: flex;
      align-items: center;
      border-radius: 100%;
    }
  }

  svg {
    fill: $text-01;
    width: 100%;
    height: 100%;
  }
}

.box {
  transition: all 150ms;
  background-color: $brand-02;
  top: 0;
  left: 0;
  display: block;
  position: absolute;
  z-index: -1;
  height: $size-l;
  width: $size-l;
  border-radius: 100%;
  pointer-events: none;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}
